import * as React from "react";
import {graphql} from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';
import WorkflowHero from '../components/WorkflowHero';
import WorkflowAbout from '../components/WorkflowAbout';
import WorkflowFeature from '../components/WorkflowFeature';
import SectionStep from '../components/SectionStep';
import SectionStatistics from "../components/SectionStatistics";
import SectionTestimonial from "../components/SectionTestimonial";
import SectionCTA from '../components/SectionCTA';

// markup
const WorkflowPage = () => {
  return (
    <Layout>
      <Seo />
      <WorkflowHero />
      <WorkflowAbout />
      <WorkflowFeature />
      <SectionStep />
      <SectionStatistics />
      <SectionTestimonial />
      <SectionCTA />
    </Layout>
  )
};

export default WorkflowPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "workflow"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;