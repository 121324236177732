import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const SectionStatistics = () => {
  const {t} = useTranslation();
  return(
    <section className="text-blue-800 body-font py-8 px-4 lg:px-20">
      <div className="my-cont">
        <div className="flex flex-wrap">
          <div className="px-1 md:px-4 py-4 md:w-1/4 w-1/2">
            <h2 className="title-font font-semibold text-5xl">75%</h2>
            <p className="leading-relaxed text-lg">{t('statistics.shortentime')}</p>
          </div>
          <div className="px-1 md:px-4 py-4 md:w-1/4 w-1/2">
            <h2 className="title-font font-semibold text-5xl">33%</h2>
            <p className="leading-relaxed text-lg">{t('statistics.increasedproductivity')}</p>
          </div>
          <div className="px-1 md:px-4 py-4 md:w-1/4 w-1/2">
            <h2 className="title-font font-semibold text-5xl">23%</h2>
            <p className="leading-relaxed text-lg">{t('statistics.reducedcost')}</p>
          </div>
          <div className="px-1 md:px-4 py-4 md:w-1/4 w-1/2">
            <h2 className="title-font font-semibold text-5xl">18%</h2>
            <p className="leading-relaxed text-lg">{t('statistics.increasedrevenue')}</p>
          </div>
        </div>
        <div className='px-1 md:px-4 py-4 text-gray-500 text-sm'>* {t('statistics.from')}</div>
      </div>
    </section>
  );
}

export default SectionStatistics;
