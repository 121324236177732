import React from 'react';

const Divider = ({title}) => (
  <div className="my-hr">
    <h1>{title}</h1>
    <div></div>
  </div>
);

export default Divider;
