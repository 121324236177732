import React from 'react';
import {StaticImage} from "gatsby-plugin-image"
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';

const WorkflowHero = () => {
  const {t} = useTranslation();
  return(
    <section className="text-blue-900 bg-blue-50 body-font pt-5">
      <div className="my-cont flex md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="text-4xl mb-5 font-normal mt-0">{t('hero.title')}</h1>
          <h3 className="mb-8 text-2xl">{t('hero.tag')}</h3>
          <div className="flex justify-center">
            <Link className="btn" to="/inquiry">{t('hero.but')}</Link>
            <Link className="btn-2 ml-10 sm:block" to="/solutions">{t('hero.but2')}</Link>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <StaticImage className="w-5/6" src="../images/hero.png" alt="Digital transformation" />
          {/* <img className="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" /> */}
        </div>
      </div>
    </section>
  );
};

export default WorkflowHero;
