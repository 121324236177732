import React from 'react';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const SectionCTA = () => {
  const {t, i18n} = useTranslation();
  return(
    <section className="text-gray-600 body-font pt-5 px-5">
      <div className="container px-8 py-16 mx-auto bg-blue-900 rounded-lg">
        <div className="lg:w-3/5 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
          <h1 className="flex-grow sm:pr-8 text-3xl font-medium title-font text-yellow-300">{t('cta.title')}</h1>
          <a className="flex-shrink-0 text-blue-900 hover:text-blue-800 bg-yellow-300 hover:bg-yellow-400 border-0 py-2 px-8 focus:outline-none rounded text-2xl mt-10 sm:mt-0" 
            href={`https://app.iohub.tech/?lng=${i18n.language.toLowerCase().substring(0, 2)}`}>{t('cta.but')}</a>
        </div>
      </div>
    </section>
  );
};

export default SectionCTA;
