import React from 'react';
import {StaticImage} from "gatsby-plugin-image"
import {useTranslation} from 'gatsby-plugin-react-i18next';

const WorkflowAbout = () => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font pt-5">
      <div className="my-cont flex md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <StaticImage src="../images/frames/frame1.png" alt="Workflow editor" className="object-cover object-center rounded" />
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <h1 className="title-font text-4xl mb-4 font-medium text-blue-800">{t('about.title')}</h1>
          <p className="mb-8 text-2xl leading-relaxed">{t('about.desc')}</p>
        </div>
      </div>
    </section>
  );
};

export default WorkflowAbout;
